import React from "react";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import AdminReset from "/src/containers/forgotpass/admin-reset";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import SetupContainer from "/src/setup-container";

const AdminResetPage = () => {
  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Sign in`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <AdminReset />
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default AdminResetPage;
